import React from "react"

const Chart = props => {
    return (
        <section className="chart-wrap">
            <div className="header pc">
                <img src="https://stgokulabweb.blob.core.windows.net/images/services/laundry_support/us_chart.png" alt="Chart"/>
                <div className="title">
                    7割以上のお客さまが<span className="yellow-underline">「清潔さ」を理由に</span><br />
                    Baluko Laundry Placeを選んでいます。
                </div>
            </div>
            <div className="header sp">
                <div className="title">
                    7割以上のお客さまが<span className="yellow-underline">「清潔さ」を理由に</span><br />
                    Baluko Laundry Placeを選んでいます。
                </div>
                <img src="https://stgokulabweb.blob.core.windows.net/images/services/laundry_support/us_chart.png" alt="Chart"/>
            </div>
            <div className="footer">※自社調べ。利用者に向けアンケートにて「コインランドリーの中でBaluko Laundry Placeを選ぶ理由」として「清潔だから」を選んだ回答の割合。（調査人数：725人）</div>
        </section>
    )
}
export default Chart
