import React from "react"

const Service = props => {
    return (
        <section className="service-wrap">
            <div className="section-title-wrap">
                <h2 className="title">サービス</h2>
                <p className="sub">Services</p>
            </div>
            <p className="lead">コールセンター・運営管理・機器メンテナンスをOKULABが代行します。</p>
            <div className="contents">
                <div className="row">
                    <div className="title-wrap bg-blue">
                        <h4 className="title">コールセンター</h4>
                    </div>
                    <div className="description-wrap">
                        <p className="sub-title">24時間の電話問い合わせ対応</p>
                        <ul className="description">
                            <li>フリーダイヤル応答</li>
                            <li>エスカレーション対応</li>
                            <li>オンサイト対応</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="title-wrap bg-blue-green">
                        <h4 className="title">清掃・集金・<br className="sp"/>巡回</h4>
                    </div>
                    <div className="description-wrap">
                        <p className="sub-title">店舗巡回、365日対応</p>
                        <ul className="description">
                            <li>清掃マニュアルに準じたフィルター及び店内清掃</li>
                            <li>洗剤・備品補充</li>
                            <li>集金・両替金補充</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="title-wrap bg-gray-black">
                        <h4 className="title">定期<br className="sp"/>メンテナンス</h4>
                    </div>
                    <div className="description-wrap">
                        <p className="sub-title">1年に1回の実施をおすすめ</p>
                        <ul className="description">
                            <li>機器オーバーホール（分解洗浄）</li>
                            <li>エアコンオーバーホール</li>
                            <li>ダクト清掃</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Service
