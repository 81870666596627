import React from "react"

const Journal = props => {
    return (
        <section className="journal-wrap">
            <div className="journal">
                <img src="https://stgokulabweb.blob.core.windows.net/images/services/laundry_support/us_baruko-seiketu.jpg"
                     alt="Journal Image"/>
                <div className="text-wrap">
                    <h4 className="title">バルコが清潔であり続ける理由</h4>
                    <p className="body">
                        「バルコはいつ訪れても「清潔感」があって気持ちがいい」お客さまから、そんな嬉しい声をいただくことがあります。<br className="sp"/><br className="sp"/>
                        洗濯する場所が清潔なのは当然のこと。いつでも「自宅の洗濯機よりもキレイ！」という体験をしてほしいから、「清潔さ」はバルコの大事な要素なのです。<br className="sp"/><br className="sp"/>
                        見えるところも、見えないところも。いつ訪れても、清潔であり続けるにはどうしたらいいのだろう。これまで清掃の方法や清掃用具などを何度も見直しながら、365日、1日も欠かさず清掃を続けています。
                        <a className="journal-link-text"
                           href="https://journal.baluko.jp/articles/cleanliness/"
                           target="_blank">
                            続きを読む
                            <img src="https://stgokulabweb.blob.core.windows.net/images/services/laundry_support/icon_ex_link.svg"
                                 alt="Link Icon" />
                        </a>
                    </p>
                    <a className="journal-link-btn" href="https://journal.baluko.jp" target="_blank">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/laundry_support/baluko-today.png"
                             alt="Link Button" />
                    </a>
                </div>
            </div>
        </section>
    )
}
export default Journal
