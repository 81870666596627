import React, { useEffect } from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import * as FadeIn from "components/fadein"
import PageLead from "../../../components/pageLead";
import Faq from "../../../components/faq";
import ContentFooter from "../../../components/contentFooter";
import EyeCatch from "../../../components/eyeCatch";
import Service from "../../../components/support/service";
import Chart from "../../../components/support/chart";
import Journal from "../../../components/support/journal";


const LaundrySupportPage = () => {
    useEffect(() => {
    }, [])

    return (
        <Layout>
            <div className="laundry-support-page">
                <PageLead sub="SERVICE"
                          title="コインランドリー運営支援"
                          description="Baluko Laundry Placeの運営品質をすべてのコインランドリーで。\nOKULAB ランドリーオーナーズサポート"
                          imageUrl="https://stgokulabweb.blob.core.windows.net/images/services/laundry_support/us_unei-support.jpg"
                          linkUrl="/contact"
                />
                <div className="contents">
                    <FadeIn.Up>
                        <EyeCatch title="コインランドリー運営でお困り・お悩みをお持ちのオーナー様へ"
                                  description="<ul><li>日々の清掃、売上金回収を委託したい。</li><li>利用者からの問い合わせやトラブル対応の負担が大きい。</li><li>今の清掃委託先の品質に満足できない。</li><li>不具合や故障が頻発すると困るが、メンテナンスをどうしたら良いかわからない。</li></ul>"
                                  imageUrl="https://stgokulabweb.blob.core.windows.net/images/services/laundry_support/us_illustration.png"/>
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <Service />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <Chart />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <Journal />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <Faq data='[
                            {
                                "q": "委託開始まで、どのくらいの時間がかかりますか？",
                                "a": "お申し込み後、2週間～1カ月程度で開始が可能です。"
                            },
                            {
                                "q": "清掃のみを委託することは可能ですか？",
                                "a": "はい、可能です。毎日の清掃はもちろん、隔日の清掃などご要望に応じたサービスをご提案させていただきます。"
                            },
                            {
                                "q": "機器の故障など、トラブル発生時のみのスポット対応の依頼は可能ですか？",
                                "a": "清掃や店舗巡回などのサービスのご利用がある場合に、スポットのご依頼をいただくことが可能です。"
                            },
                            {
                                "q": "費用はどのくらいかかりますか？",
                                "a": "エリアやご希望の内容により異なりますが、清掃の委託をご希望の場合、月額51,000円～(税抜)となります。まずはお気軽にお問合せください。"
                            }
                        ]'/>
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <ContentFooter
                            description="200店舗以上の実績を基にしたノウハウで、コインランドリー運営をサポートします。\nお気軽にお問い合わせください。"
                            buttonText="資料請求する"
                            href="/contact"
                        />
                    </FadeIn.Up>
                </div>
            </div>
        </Layout>
    )
}
export const Head = () => (
    <Seo
        title="ランドリー運営支援"
        description="コインランドリー運営でお困り・お悩みをお持ちのオーナー様へ。コールセンター、運営管理、機器メンテナンスをOKULAB（オクラボ）が代行します。"
        topFlag={true}
    />
)

export default LaundrySupportPage
